<template>
  <div class="row" id="input-row">
    <div class="col">
      <OrderForm
        :showOrderForm="showOrderForm"
        :customer="customer"
        :newCustomer="customerForm"
        :passInOrder="order"
        @resetOrder="resetOrder"
      >
        <template v-slot:beforeForm>
          <h1 class="box-title">落單 / Input New Order</h1>

          <b-form
            @submit.prevent="findCustomerByPhone"
            novalidate
            v-if="!showOrderForm"
          >
            <b-row class="gx-2 flex-nowrap">
              <label class="col-form-label ol-auto">訂車人電話</label>
              <b-col>
                <SearchInput
                  key="order-input-search"
                  index="Customers"
                  title="name"
                  subtitle="otherPhones"
                  @change="updatePhone"
                />

                <template v-if="!showOrderForm">
                  <div class="text-center mt-2">
                    <a href="#" @click.prevent="newCustomerOrderForm"
                      >新增新客戶訂單</a
                    >
                  </div>
                </template>
              </b-col>
            </b-row>
          </b-form>

          <template v-if="!$_.isEmpty(customer)">
            <div class="box blue-box sm-box mt-3 mb-2">
              <b-row class="gx-2">
                <b-col cols="4">
                  <router-link
                    target="_blank"
                    :to="{
                      path: 'customers',
                      query: { customerId: customer.customerId },
                    }"
                    class="no-underline float-right"
                  >
                    <b-icon-pencil></b-icon-pencil>
                  </router-link>

                  訂車人電話
                  <div class="box sm-box compact-box mt-2">
                    {{ customer.phone }}
                  </div>
                </b-col>
                <b-col cols="4">
                  訂車人
                  <div class="box sm-box compact-box mt-2">
                    {{ customer.name }}
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="text-right">&nbsp;</div>
                  <div class="d-flex mt-2 pt-1">
                    <b-button
                      @click="existingCustomerOrderForm"
                      class="mr-1 flex-grow-1"
                      size="sm"
                      >新增訂單</b-button
                    >
                    <b-button
                      class="flex-grow-1"
                      @click="getCustomerOrders"
                      variant="outline-light"
                      size="sm"
                      >過去記錄</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-alert
              v-if="
                $_.isArray(customer.otherPhones) &&
                  customer.otherPhones.length > 0
              "
              variant="primary"
              class="p-2 mb-2"
              :show="
                $_.isArray(customer.otherPhones) &&
                  customer.otherPhones.length > 0
              "
            >
              <small>
                <strong>其他電話:</strong>
                <span
                  class="mr-2"
                  v-for="(op, op_index) in customer.otherPhones"
                  :key="`other-phone-${op_index}`"
                >
                  {{ op }}

                  <small
                    v-if="
                      $_.isArray(customer.otherPhoneDescriptions) &&
                        customer.otherPhoneDescriptions[op_index]
                    "
                    >({{ customer.otherPhoneDescriptions[op_index] }})</small
                  >
                </span>
              </small>
            </b-alert>

            <b-alert variant="warning" class="p-2" :show="!!customer.note">{{
              customer.note
            }}</b-alert>

            <b-alert variant="dark" class="p-2" :show="!!customer.blacklisted"
              ><small>黑名單!</small></b-alert
            >
          </template>
        </template>
      </OrderForm>
    </div>
    <transition name="slide-width" :duration="300">
      <div class="col-auto pl-0" id="recent-orders-col" v-if="!hideRecentorder">
        <div id="recent-orders" class="box gray-box full-box">
          <b-button
            class="float-right"
            variant="outline-primary"
            size="sm"
            id="btn-toggleRecentOrder"
            @click="hideRecentorder = true"
          >
            <b-icon icon="arrow-bar-right"></b-icon>
          </b-button>
          <h2 class="box-title">最近記錄 / Recent Order</h2>

          <template v-if="!$_.isEmpty(orders)">
            <RecentOrder
              @useOrder="useRecentOrder($event)"
              v-for="(o, index) in orders"
              :key="`ri-${index}`"
              :order="o"
            >
            </RecentOrder>
          </template>
          <p v-else>沒有過去的訂單</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { omit, cloneDeep } from "lodash";

import OrderForm from "@/components/OrderForm";
import RecentOrder from "@/components/RecentOrder";
import SearchInput from "@/components/SearchInput";

const emptyOrder = {
  cost: 0,
  cost_407: 0,
  customer_isnot_passenger: false,
  date: "",
  dateTime: "",
  handcarry_count: 0,
  instruction: "",
  language: "cantonese",
  locations: ["", ""],
  location_names: ["", ""],
  wait_times: [],
  luggage_count: 0,
  on_behalf: "receive_on_behalf",
  on_behalf_cost: 0,
  other_luggages: [""],
  passenger: {},
  passenger_count: 0,
  payment_method: "Cash",
  receipt: 0,
  remark: "",
  status: "Confirmed",
  time: "",
};

export default {
  name: "InputPage",
  data: function() {
    return {
      field1: "",
      showOrderForm: false,
      hideRecentorder: true,
      customerForm: {
        name: "",
        phone: "",
      },
      order: {},
    };
  },
  components: {
    OrderForm,
    RecentOrder,
    SearchInput,
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.recentOrders,
      cloneOrder: (state) => state.orders.cloneOrder,
      customer: (state) => state.customers.singleCustomer,
      locations: (state) => state.locations.locations,
      location_categories: (state) => state.locations.categories,
    }),
  },
  methods: {
    ...mapActions({
      getSingleCustomer: "customers/getSingle",
    }),
    async newCustomerOrderForm() {
      await this.$store.commit("customers/resetCustomer");
      this.showOrderForm = !this.showOrderForm;
      this.order = emptyOrder;
    },
    existingCustomerOrderForm() {
      this.showOrderForm = !this.showOrderForm;
      this.order = emptyOrder;
    },
    useRecentOrder(order) {
      this.showOrderForm = true;
      this.hideRecentorder = true;

      const _order = Object.assign({}, order, {
        orderId: null,
        notice: null,
        color: null,
        address_color: null,
        location_colors: null,
        location_names: order?.locations?.map((l) => ""),
        wait_times: null,
        instruction_color: null,
        remark_color: null,
        no_pay_cost: null,
        tips: null,
        driverId: null,
        dateTime: this.order.dateTime,
        date: this.order.date,
        time: this.order.time,
        locations_latlngs: null,
        fromMobile: null,
      });

      delete _order.date;
      delete _order.time;
      delete _order.dateTime;

      this.order = _order;
    },
    updatePhone(phone) {
      this.customerForm.phone = phone;
    },
    resetOrder() {
      Object.assign(this.$data, {
        showOrderForm: false,
        hideRecentorder: true,
        customerForm: {
          phone: "",
        },
        order: {},
      });
    },
    async findCustomerByPhone() {
      if (this.customerForm.phone) {
        await this.$store.dispatch("customers/get", {
          filters: {
            phone: this.customerForm.phone,
          },
        });
      }
    },
    async getCustomerOrders() {
      await this.$store.dispatch("orders/get", {
        count: 100,
        order: "desc",
        filters: {
          customerId: this.customer.customerId,
        },
        stateKey: "recentOrders",
      });

      this.hideRecentorder = false;
    },
  },
  async mounted() {
    if (this.cloneOrder) {
      await this.getSingleCustomer(this.cloneOrder.customerId);
      await this.useRecentOrder(cloneDeep(this.cloneOrder));
      await this.$store.commit("orders/resetCloneOrder");
    }
  },
  activated() {},
};
</script>

<style lang="scss" scoped></style>
