var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"id":"input-row"}},[_c('div',{staticClass:"col"},[_c('OrderForm',{attrs:{"showOrderForm":_vm.showOrderForm,"customer":_vm.customer,"newCustomer":_vm.customerForm,"passInOrder":_vm.order},on:{"resetOrder":_vm.resetOrder},scopedSlots:_vm._u([{key:"beforeForm",fn:function(){return [_c('h1',{staticClass:"box-title"},[_vm._v("落單 / Input New Order")]),(!_vm.showOrderForm)?_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.findCustomerByPhone($event)}}},[_c('b-row',{staticClass:"gx-2 flex-nowrap"},[_c('label',{staticClass:"col-form-label ol-auto"},[_vm._v("訂車人電話")]),_c('b-col',[_c('SearchInput',{key:"order-input-search",attrs:{"index":"Customers","title":"name","subtitle":"otherPhones"},on:{"change":_vm.updatePhone}}),(!_vm.showOrderForm)?[_c('div',{staticClass:"text-center mt-2"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.newCustomerOrderForm($event)}}},[_vm._v("新增新客戶訂單")])])]:_vm._e()],2)],1)],1):_vm._e(),(!_vm.$_.isEmpty(_vm.customer))?[_c('div',{staticClass:"box blue-box sm-box mt-3 mb-2"},[_c('b-row',{staticClass:"gx-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('router-link',{staticClass:"no-underline float-right",attrs:{"target":"_blank","to":{
                    path: 'customers',
                    query: { customerId: _vm.customer.customerId },
                  }}},[_c('b-icon-pencil')],1),_vm._v(" 訂車人電話 "),_c('div',{staticClass:"box sm-box compact-box mt-2"},[_vm._v(" "+_vm._s(_vm.customer.phone)+" ")])],1),_c('b-col',{attrs:{"cols":"4"}},[_vm._v(" 訂車人 "),_c('div',{staticClass:"box sm-box compact-box mt-2"},[_vm._v(" "+_vm._s(_vm.customer.name)+" ")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-right"}),_c('div',{staticClass:"d-flex mt-2 pt-1"},[_c('b-button',{staticClass:"mr-1 flex-grow-1",attrs:{"size":"sm"},on:{"click":_vm.existingCustomerOrderForm}},[_vm._v("新增訂單")]),_c('b-button',{staticClass:"flex-grow-1",attrs:{"variant":"outline-light","size":"sm"},on:{"click":_vm.getCustomerOrders}},[_vm._v("過去記錄")])],1)])],1)],1),(
              _vm.$_.isArray(_vm.customer.otherPhones) &&
                _vm.customer.otherPhones.length > 0
            )?_c('b-alert',{staticClass:"p-2 mb-2",attrs:{"variant":"primary","show":_vm.$_.isArray(_vm.customer.otherPhones) &&
                _vm.customer.otherPhones.length > 0}},[_c('small',[_c('strong',[_vm._v("其他電話:")]),_vm._l((_vm.customer.otherPhones),function(op,op_index){return _c('span',{key:("other-phone-" + op_index),staticClass:"mr-2"},[_vm._v(" "+_vm._s(op)+" "),(
                    _vm.$_.isArray(_vm.customer.otherPhoneDescriptions) &&
                      _vm.customer.otherPhoneDescriptions[op_index]
                  )?_c('small',[_vm._v("("+_vm._s(_vm.customer.otherPhoneDescriptions[op_index])+")")]):_vm._e()])})],2)]):_vm._e(),_c('b-alert',{staticClass:"p-2",attrs:{"variant":"warning","show":!!_vm.customer.note}},[_vm._v(_vm._s(_vm.customer.note))]),_c('b-alert',{staticClass:"p-2",attrs:{"variant":"dark","show":!!_vm.customer.blacklisted}},[_c('small',[_vm._v("黑名單!")])])]:_vm._e()]},proxy:true}])})],1),_c('transition',{attrs:{"name":"slide-width","duration":300}},[(!_vm.hideRecentorder)?_c('div',{staticClass:"col-auto pl-0",attrs:{"id":"recent-orders-col"}},[_c('div',{staticClass:"box gray-box full-box",attrs:{"id":"recent-orders"}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary","size":"sm","id":"btn-toggleRecentOrder"},on:{"click":function($event){_vm.hideRecentorder = true}}},[_c('b-icon',{attrs:{"icon":"arrow-bar-right"}})],1),_c('h2',{staticClass:"box-title"},[_vm._v("最近記錄 / Recent Order")]),(!_vm.$_.isEmpty(_vm.orders))?_vm._l((_vm.orders),function(o,index){return _c('RecentOrder',{key:("ri-" + index),attrs:{"order":o},on:{"useOrder":function($event){return _vm.useRecentOrder($event)}}})}):_c('p',[_vm._v("沒有過去的訂單")])],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }